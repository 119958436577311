<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <LayoutProductCollectionDetail
        v-model="filterSlug"
        :filters-response="productFiltersResponse"
        :refresh-filters="refreshProductFilters"
        :title="category?.name ?? '-'"
    >
        <!--  CATEGORY INFO  -->
        <!--  includes the description and sub-categories  -->
        <CategoryInfo v-if="category"
                      class="sim-pc-detail__info"
                      :description="category.description ?? ''"
                      :sub-categories="subCategoriesResponse?.getItems()!"
        />
    </LayoutProductCollectionDetail>
</template>

<script lang="ts" setup>
import { PRODUCT_CARD_EMBEDS } from '../assets/ts/constants/embeds'

const { page } = useCurrentPage()

const route = useRoute()
const filterSlug = ref<string>(route.fullPath)

const [
    { item: category },
    { data: subCategoriesResponse },
    {
        data: productFiltersResponse,
        refresh: refreshProductFilters,
    },
] = await Promise.all([
    // Category
    useCategoriesApiService()
        .onlyAttrs([
            CategoryModel.ATTR_NAME,
            CategoryModel.ATTR_DESCRIPTION,
        ])
        .embed([
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
            CategoryModel.EMBED_IMAGE_URL,
        ])
        .forId(page.value!.model!.id)
        .useGet(),
    // Sub-Categories
    useCategoriesApiService()
        .whereEquals(CategoryModel.ATTR_PARENT_ID, page.value!.model!.id)
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
            CategoryModel.ATTR_DESCRIPTION,
        ])
        .embed([
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
            CategoryModel.EMBED_IMAGE_URL,
            CategoryModel.EMBED_URLS,
        ])
        .useGet(),
    // Products
    useProductsFilterApiService()
        .embed([
            ...PRODUCT_CARD_EMBEDS,
        ])
        .usePost({ request_uri: filterSlug }, { watch: false }),
])

</script>

<style lang="scss" scoped>

</style>
